<template>

    <div class="col-12">
        <div v-if="errors">
            {{ errors }}
        </div>
        <form>

            <div class="row admin-filters-wrapper">
                <div class="form-search-wrap col-12">
                    <div class="choices-filter-group double position-relative">
                        <input class="padding-right-big" type="text" name="keyword" id="keyword" placeholder="Enter a keyword, order number, program name, or network name" v-model="query_keyword" >
                        <label class="search-icon-placement no-border search-button" for="keyword">
                            <button @click.prevent="keyword_search()" class="btn btn-primary">Search</button>
                        </label>
                    </div>
                </div>

                <div class="col-3 pt-2">
                    <div class="form-floating mb-3">
                        <v-select multiple id="query_order_status" :placeholder="`Order Status`" v-model="query_order_status" label="text" :reduce="text => text.id" :options="order_status_options" ></v-select>
                    </div>
                </div>

                <div class="col-3 pt-2 single">
                    <v-select id="query_order_type" :placeholder="`Order Type`" v-model="query_order_type" label="text" :reduce="text => text.id" :options="order_type_options" ></v-select>
                </div>
                <div class="col-3 pt-2">
                    <v-select multiple id="query_file_status" :placeholder="`Video File Status`" v-model="query_file_status"  :options="file_status_options" ></v-select>
                </div>
                <div class="col-3 pt-2">
                    <v-select multiple id="query_cc_file_status" :placeholder="`CC File Status`" v-model="query_cc_file_status"  :options="cc_file_status_options" ></v-select>
                </div>
                <div class="col-3 pt-2">
                    <v-select id="query_email_status" :placeholder="`Email Status`" v-model="query_email_status" label="text" :reduce="text => text.id" :options="email_status_options" ></v-select>
                </div>
                <div class="col-3 pt-2">
                    <v-select id="query_month" :placeholder="`Month`" v-model="query_month" :options="month_options" ></v-select>
                </div>
                <div class="col-3">
                    <span class="small">Email Send Date - Start</span><br>
                    <label class="d-inline-flex align-items-center border input-wrapper input-wrapper-date">
                        <calendar-icon></calendar-icon>
                        <flat-pickr
                            id="query_send_date_start"
                            v-model="query_send_date_start"
                            value=""
                            :config="{
                                enableTime: false,
                                dateFormat: 'm-d-Y',
                                altInput: true,
                                altFormat: 'F j, Y',
                            }"
                            placeholder="Email Send Date - Start"
                        ></flat-pickr>
                    </label>
                </div>
                <div class="col-3">
                    <span class="small">Email Send Date - End</span><br>
                    <label class="d-inline-flex align-items-center border input-wrapper input-wrapper-date">
                        <calendar-icon></calendar-icon>
                        <flat-pickr
                            id="query_send_date_end"
                            v-model="query_send_date_end"
                            value=""
                            :config="{
                                enableTime: false,
                                dateFormat: 'm-d-Y',
                                altInput: true,
                                altFormat: 'F j, Y',
                            }"
                            placeholder="Email Send Date - End"
                        ></flat-pickr>
                    </label>
                </div>
                <div class="offset-10 col-2 pt-2 ">
                    <a class="pull-right clear-button no-border cursor-pointer" v-on:click.stop="clearSearch()"><close-icon class="svg-16 svg-accent"></close-icon> Clear Search</a>
                </div>
            </div>

        </form>
        <br/>

        <div class="entries-table-wrap">
            <table id="sortable-table" class="table-with-full-hover nice-table">
                <thead>

                <tr class="tableHeaderRow">
                    <th style="width: 145px;" class="th-entry pl-2" v-on:click="setSort('order_id')" :class="{ 'active-sort': sort === 'order_id' }">
                        Order #
                        <up-chevron-icon v-if="((sort_order != 'desc') && (sort == 'order_id'))" />
                        <down-chevron-icon v-else />
                    </th>
                    <th style="width: 235px;" class="th-category" v-on:click="setSort('category')" :class="{ 'active-sort': sort === 'category' }">
                        Category
                        <up-chevron-icon v-if="((sort_order != 'desc') && (sort == 'category'))" />
                        <down-chevron-icon v-else />
                    </th>
                    <th style="width: 80px;" class="th-year" v-on:click="setSort('year')" :class="{ 'active-sort': sort === 'year' }">
                        Year
                        <up-chevron-icon v-if="((sort_order != 'desc') && (sort == 'year'))" />
                        <down-chevron-icon v-else />
                    </th>
                    <th class="th-type" v-on:click="setSort('order_type')" :class="{ 'active-sort': sort === 'order_type' }">
                        Type
                        <up-chevron-icon v-if="((sort_order != 'desc') && (sort == 'order_type'))" />
                        <down-chevron-icon v-else />
                    </th>
                    <th class="th-network" v-on:click="setSort('network')" :class="{ 'active-sort': sort === 'network' }">
                        Network
                        <up-chevron-icon v-if="((sort_order != 'desc') && (sort == 'network'))" />
                        <down-chevron-icon v-else />
                    </th>
                    <th class="th-program" v-on:click="setSort('program')" :class="{ 'active-sort': sort === 'program' }">
                        Program
                        <up-chevron-icon v-if="((sort_order != 'desc') && (sort == 'program'))" />
                        <down-chevron-icon v-else />
                    </th>
                    <th style="width: 130px;" class="th-status" v-on:click="setSort('order_status')" :class="{ 'active-sort': sort === 'order_status' }">
                        Status
                        <up-chevron-icon v-if="((sort_order != 'desc') && (sort == 'order_status'))" />
                        <down-chevron-icon v-else />
                    </th>
                    <th style="width: 130px;" class="th-status th-email-status">
                        Email Status
                    </th>
                </tr>
                </thead>
                <tbody v-for="(order) in orders.data">
                <tr @click="loadOrder(order.order_id)" :id="'order-'+order.order_id" style="cursor: pointer">
                    <td  class="pl-2"><div>{{ order.order_id }}</div></td>
                    <td><div>{{ order.category }}</div></td>
                    <td><div>{{ order.year }}</div></td>
                    <td><div>{{ order.order_type }}</div></td>
                    <td><div>{{ order.network }}</div></td>
                    <td><div>{{ order.program }}</div></td>
                    <td class="status order-status">
                        <div class="">
                            <span class="status-icon" :class="'orderstatus--' + order.order_status"></span>
                            {{ order_status_options.find(option => option.id === order.order_status)?.text }}
                        </div>
                    </td>
                    <td class="status email-status">
                        <div v-for="email in listItems(order.emails)" :class="dateCompare(email)">
                            <span class="status-icon" :class="'emailstatus--' + email.status"></span>
                            {{ email.month }}:
                            {{ email_status_options.find(option => option.id === email.status)?.text }}
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div style="float:right;">
            <pagination :data="orders" @pagination-change-page="paginationCalculator" :limit="3">
                <span slot="prev-nav">&lt; Previous</span>
                <span slot="next-nav">Next &gt;</span>
            </pagination>
        </div>
        <div class="dataTables_info" v-if="to">Showing 1-{{to}} of {{ total }} orders</div>
        <div class="dataTables_info" v-else>Showing 0 orders</div>
        <div class="dataTables_length admin-entry-table">
            <label>
                <span class="pr-2">Show</span>
                <select class="plain-select num-entries-select" v-model.number="num">
                    <option :value="10">10 Orders</option>
                    <option :value="20">20 Orders</option>
                    <option :value="50">50 Orders</option>
                    <option :value="100">100 Orders</option>
                </select>
                <span>per page</span>
            </label>
        </div>
    </div>
</template>

<script>
    import CloseIcon from "@/Icons/close.vue";
    import DownChevronIcon from "@/Icons/down_chevron.vue";
    import UpChevronIcon from "@/Icons/up-chevron.vue";
    import SearchIcon from "@/Icons/search.vue";
    import CalendarIcon from "@/Icons/calendar.vue";
    export default {
        name: "OrdersAdminSearch",
        components: {CalendarIcon, SearchIcon, UpChevronIcon, DownChevronIcon, CloseIcon},
        data() {
            const storedValues = {
                search: localStorage.getItem('fyc-admin-history-search'),
                searchStatus: localStorage.getItem('fyc-admin-history-search-status'),
                searchType: localStorage.getItem('fyc-admin-history-search-type'),
                searchFileStatus: localStorage.getItem('fyc-admin-history-search-filestatus'),
                searchMonth: localStorage.getItem('fyc-admin-history-search-month'),
                searchEmailStatus: localStorage.getItem('fyc-admin-history-search-email-status'),
                searchDateSendStart: localStorage.getItem('fyc-admin-history-search-event-send-start'),
                searchDateSendEnd: localStorage.getItem('fyc-admin-history-search-event-send-end'),
            };

            return {
                reloadOnQueryChange: false,
                errors: false,
                num: 20,
                page: 1,
                total: 0,
                to: 0,
                sort: '',
                sort_order: 'desc',
                orders: {},
                query_keyword: (storedValues.search && storedValues.search !== 'null') ? storedValues.search : '',
                query_order_status: (storedValues.searchStatus && storedValues.searchStatus !== 'null') ? storedValues.searchStatus : '',
                query_order_type: (storedValues.searchType && storedValues.searchType !== 'null') ? storedValues.searchType : '',
                query_file_status: (storedValues.searchFileStatus && storedValues.searchFileStatus !== 'null') ? storedValues.searchFileStatus : '',
                query_cc_file_status: (storedValues.searchCCFileStatus && storedValues.searchCCFileStatus !== 'null') ? storedValues.searchCCFileStatus : '',
                query_month: (storedValues.searchMonth && storedValues.searchMonth !== 'null') ? storedValues.searchMonth : '',
                query_email_status: (storedValues.searchEmailStatus && storedValues.searchEmailStatus !== 'null') ? storedValues.searchEmailStatus : '',
                query_send_date_start: (storedValues.searchDateSendStart && storedValues.searchDateSendStart !== 'null') ? storedValues.searchDateSendStart : '',
                query_send_date_end: (storedValues.searchDateSendEnd && storedValues.searchDateSendEnd !== 'null') ? storedValues.searchDateSendEnd : '',
                order_status_options: [
                    { text: "In Progress", id: "in_progress" },
                    { text: "Submitted", id: "submitted" },
                    { text: "Approved For Build", id: "approved_for_build" },
                    { text: "Canceled", id: "canceled" },
                    { text: "Needs More Information", id: "need_more_info" },
                    { text: "User Reported", id: "user_reported"},
                    { text: "Pre-Approved", id: "pre_approved" },
                    { text: "Send To Viewing Platform", id: "approved" },
                    { text: "Ineligible", id: "ineligible" },
                    { text: "Live", id: "live" },
                    { text: "Live - Checked", id: "live_checked" },
                ],
                order_type_options: [
                    { text: 'Viewing Platform', id: 'Viewing Platform' },
                    { text: 'Network', id: 'Network' },
                ],
                month_options: [
                    'March',
                    'April',
                    'May',
                    'June',
                ],
                email_status_options: [
                    { text: "In Progress", id: "in_progress" },
                    { text: "Assets Submitted", id: "submitted" },
                    { text: "Asset Need More Info", id: "need_more_info" },
                    { text: "Internal Review", id: "internal_review" },
                    { text: "Approved For Go Live", id: "approved" },
                    { text: "Build Underway", id: "building" },
                    { text: "Build Complete", id: "build_complete" },
                    { text: "Published & Sent", id: "published" },
                    { text: "Canceled", id: "canceled" },
                ],
                file_status_options: [
                    'Uploading',
                    'Upload Failed',
                    'Upload Successful',
                    'Closed Caption File Submitted',
                    'Processing',
                    'Processing Failed',
                    'Under Review',
                    'Need More Info',
                    'Video Approved',
                    'Canceled',
                    'Deleted',
                ],
                cc_file_status_options: [
                    'Processing',
                    'Processed'
                ]
            }
        },
        mounted() {
            const storedValues = {
                search: localStorage.getItem('fyc-admin-history-search'),
                searchStatus: localStorage.getItem('fyc-admin-history-search-status'),
                searchType: localStorage.getItem('fyc-admin-history-search-type'),
                searchFileStatus: localStorage.getItem('fyc-admin-history-search-filestatus'),
                searchCCFileStatus: localStorage.getItem('fyc-admin-history-search-cc-filestatus'),
                searchMonth: localStorage.getItem('fyc-admin-history-search-month'),
                searchEmailStatus: localStorage.getItem('fyc-admin-history-search-email-status'),
                searchSort: localStorage.getItem('fyc-admin-history-search-sort'),
                searchSortOrder: localStorage.getItem('fyc-admin-history-search-sort-order'),
                searchDateSendStart: localStorage.getItem('fyc-admin-history-search-event-send-start'),
                searchDateSendEnd: localStorage.getItem('fyc-admin-history-search-event-end-start'),
                searchNum: parseInt(localStorage.getItem('fyc-admin-history-search-num')),
                searchPage: parseInt(localStorage.getItem('fyc-admin-history-search-page')),
            };

            if (storedValues.searchSort && storedValues.searchSort !== 'null') {
                this.sort = storedValues.searchSort;
            }
            if (storedValues.searchSortOrder && storedValues.searchSortOrder !== 'null') {
                this.sort_order = storedValues.searchSortOrder;
            }
            if (storedValues.searchNum && storedValues.searchNum !== 'null') {
                this.num = storedValues.searchNum;
            }
            if (storedValues.searchPage && storedValues.searchPage !== 'null') {
                this.page = storedValues.searchPage;
            }
            if (storedValues.searchStatus && storedValues.search !== 'null') {
                this.query_order_status = storedValues.searchStatus.split(',');
            }
            if (storedValues.searchFileStatus && storedValues.searchFileStatus !== 'null') {
                this.query_file_status = storedValues.searchFileStatus.split(',');
            }
            if (storedValues.searchCCFileStatus && storedValues.searchCCFileStatus !== 'null') {
                this.query_cc_file_status = storedValues.searchCCFileStatus.split(',');
            }
            if (storedValues.searchType && storedValues.searchType !== 'null') {
                this.query_order_type = storedValues.searchType;
            }
            if (storedValues.searchMonth && storedValues.searchMonth !== 'null') {
                this.query_month = storedValues.searchMonth;
            }
            if (storedValues.searchEmailStatus && storedValues.searchEmailStatus !== 'null') {
                this.query_email_status = storedValues.searchEmailStatus;
            }
            if (storedValues.searchDateSendStart && storedValues.searchDateSendStart !== 'null') {
                this.query_send_date_start = storedValues.searchDateSendStart;//.split(',');
            }
            if (storedValues.searchDateSendEnd && storedValues.searchDateSendEnd !== 'null') {
                this.query_send_date_end = storedValues.searchDateSendEnd;//.split(',');
            }
            this.fetchOrders(this.page);
        },
        methods:{
            keyword_search() {
                localStorage.removeItem('fyc-admin-history-search-page');
                localStorage.setItem('fyc-admin-history-search', this.query_keyword);
                this.fetchOrders(this.page);
            },
            loadOrder(order_id){
                window.location = '/order/'+order_id;
            },
            filtersUpdated() {
                if (this.reloadOnQueryChange) {
                    this.resetPageNumber();
                    this.fetchOrders();
                }
            },
            fetchOrders(page = 1){
                let app = this;
                axios
                    .get('/api/orders', {
                        params: {
                            num: app.num,
                            page: page,
                            sort: app.sort,
                            sort_order: app.sort_order,
                            keyword: app.query_keyword,
                            order_status: app.query_order_status,
                            file_status: app.query_file_status,
                            cc_file_status: app.query_cc_file_status,
                            order_type: app.query_order_type,
                            month: app.query_month,
                            email_status: app.query_email_status,
                            send_date_start: app.query_send_date_start,
                            send_date_end: app.query_send_date_end,
                        }
                    })
                    .then(res => {
                        app.orders = res.data;
                        app.total = res.data.total;
                        app.to = res.data.to;
                        localStorage.setItem('fyc-admin-history-search-to', app.to);
                        localStorage.setItem('fyc-admin-history-search', app.query_keyword);
                        localStorage.setItem('fyc-admin-history-search-status', app.query_order_status);
                        localStorage.setItem('fyc-admin-history-search-filestatus', app.query_file_status);
                        localStorage.setItem('fyc-admin-history-search-cc-filestatus', app.query_cc_file_status);
                        localStorage.setItem('fyc-admin-history-search-type', app.query_order_type);
                        localStorage.setItem('fyc-admin-history-search-month', app.query_month);
                        localStorage.setItem('fyc-admin-history-search-email-status', app.query_email_status);
                        localStorage.setItem('fyc-admin-history-search-event-send-start', app.query_send_date_start);
                        localStorage.setItem('fyc-admin-history-search-event-send-end', app.query_send_date_end);
                        localStorage.setItem('fyc-admin-history-search-num', app.num);
                        let hashVal = window.location.hash;
                        if(hashVal && (hashVal.length)) {
                            setTimeout(function () {
                                app.$scrollTo(hashVal, 500,{
                                    offset: -61});
                            }, 300)
                        }
                    })
                    .catch(err => {
                        this.errors = 'Error contacting the server : ';
                        this.errors += err;
                        console.log(err)
                    })
                    .finally(() => {
                        this.reloadOnQueryChange = true;
                    });
            },
            setSort(sort) {
                if (this.sort == sort) {
                    this.sort_order = (this.sort_order == 'desc')?'asc':'desc';
                } else {
                    this.sort = sort;
                    this.sort_order = 'desc';
                }
                localStorage.setItem('fyc-admin-history-search-sort', this.sort);
                localStorage.setItem('fyc-admin-history-search-sort-order', this.sort_order);
                this.fetchOrders();
            },
            clearSearch: function() {
                // Disable query watcher until next fetch
                this.reloadOnQueryChange = false;

                this.query_keyword = '';
                this.sort = '';
                this.sort_order = 'desc';
                this.num = 20;
                this.query_order_status = '';
                this.query_file_status = '';
                this.query_cc_file_status = '';
                this.query_order_type = '';
                this.query_month = '';
                this.query_email_status = '';
                this.query_send_date_start = '';
                this.query_send_date_end = '';
                localStorage.removeItem('fyc-admin-history-search');
                localStorage.removeItem('fyc-admin-history-search-status');
                localStorage.removeItem('fyc-admin-history-search-filestatus');
                localStorage.removeItem('fyc-admin-history-search-type');
                localStorage.removeItem('fyc-admin-history-search-month');
                localStorage.removeItem('fyc-admin-history-search-email-status');
                localStorage.removeItem('fyc-admin-history-search-event-send-start');
                localStorage.removeItem('fyc-admin-history-search-event-end-start');
                localStorage.removeItem('fyc-admin-history-search-sort');
                localStorage.removeItem('fyc-admin-history-search-sort-order');
                localStorage.removeItem('fyc-admin-history-search-num');
                localStorage.removeItem('fyc-admin-history-search-page');

                this.fetchOrders();
            },
            paginationCalculator: function(pageIndex) {
                localStorage.setItem('fyc-admin-history-search-page', pageIndex);
                this.fetchOrders(pageIndex);
            },
            resetPageNumber() {
                this.page = 1;
                localStorage.removeItem(`fyc-admin-finance-${this.invoiceType}-page`);
            },
            dateCompare(email) {
                const currentMonth = new Date().getMonth();
                const currentYear = new Date().getFullYear();
                if (currentMonth > new Date(`${email.month} 01 ${currentYear}`).getMonth()) {
                    return 'email-status--past';
                } else if (currentMonth < new Date(`${email.month} 01 ${currentYear}`).getMonth()) {
                    return 'email-status--future';
                } else {
                    return 'email-status--current';
                }
            },
            listItems(items) {
                return _.orderBy(items, 'month_index', 'asc');
            },
        },
        watch: {
            query_order_status() {
                this.filtersUpdated();
            },
            query_file_status() {
                this.filtersUpdated();
            },
            query_cc_file_status() {
                this.filtersUpdated();
            },
            query_order_type() {
                this.filtersUpdated();
            },
            query_month() {
                this.filtersUpdated();
            },
            query_email_status() {
                this.filtersUpdated();
            },
            query_send_date_start() {
                this.filtersUpdated();
            },
            query_send_date_end() {
                this.filtersUpdated();
            },
            num() {
                this.page = 1;  // Reset to first page when number of items changed
                this.fetchOrders(this.page);
            },
        },
    }
</script>
