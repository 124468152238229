<template>
    <div :class="'video-component-' + type ">
        <div class="form-group-beside my-0 pb-2" v-if="updateallowed && role !== 'viewer'" v-show="videoobject.length == 0 || video_upload_in_progress">
            <label class="col-2 col-md-6 video-uploads-label">{{ displayName ?? 'Video Uploads' }}: <em class="yellow no-style" v-if="requiredVideoUploads">*</em></label>
            <vue-dropzone class="btn btn-primary video-uploader col-10 col-md-6" ref="videoDropzone" id="dropzoneVideo" :options="dropzoneOptionsVideo" v-on:vdropzone-sending="sendingCallback" v-on:vdropzone-file-added="acceptVideoCallback"></vue-dropzone>
            <vue-dropzone v-show="showReplaceProgressBar" class="video-uploader video-uploader-replace col-10 col-md-6" ref="videoDropzoneReplace" id="dropzoneVideoReplace" :options="dropzoneReplacementOptionsVideo" v-on:vdropzone-sending="sendingCallback" v-on:vdropzone-file-added="acceptReplaceVideoCallback"></vue-dropzone>
            <div class="entry-field__label_50 filename" v-if="videofiles.length > 0 && !showReplaceProgressBar">
                Please fill in video details below.
            </div>
        </div>
        <div class="" v-if="videofiles.length > 0">
            <div class="input-wrapper">
                <div id="top-element" class="">
                    <div class="video-component-wrapper">
                        <div class="row width-100 no-gutters">
                            <div class="left entrant-left video-filename-listing col-12 col-md-4" v-if="type !== 'event'">
                                <ul>
                                    <draggable class="drag-episode-titles" v-model="videofiles" @start="drag=true" @end="drag=false, updateWeights()" v-if="role !== 'viewer' && updateallowed">
                                        <li class="video-filename-item" :class="leftSidebarClass(index, video)" v-for="(video, index) in videofiles" @click="videoActive(index)">
                                            <div class="action-icons pull-right hide-me">
                                                <div class="d-inline-flex align-middle move pl-3 pr-2 btn-icon btn entrant-module--icon" v-if="updateallowed && add_another_video_allowed"><span class="actions">Reorder<br/>Videos</span><collapse class="svg-18"></collapse></div>
                                                <div class="d-inline-block align-middle btn-icon btn entrant-module--icon" v-if="updateallowed && videoobject.length > 0 && role !== 'viewer'" @click.prevent="activateDeleteVideoPopup(video, index)"><trash-icon></trash-icon> </div>
                                            </div>
                                            <div v-if="type === 'event'">{{ video.filename }}</div>
                                            <div v-else-if="video.episode_title">{{index+1}} - {{video.episode_title}}</div>
                                            <div v-else>{{index+1}} - Episode/Program </div>
                                        </li>
                                    </draggable>

                                    <div class="drag-episode-titles" v-else>
                                        <li class="video-filename-item" :class="leftSidebarClass(index, video)" v-for="(video, index) in videofiles" @click="videoActive(index)">
                                            <div class="action-icons pull-right hide-me">
                                                <div class="d-inline-block align-middle move pl-3 pr-2" v-if="updateallowed && add_another_video_allowed"><span class="actions">Reorder<br/>Videos</span><collapse></collapse></div>
                                                <div class="d-inline-block align-middle" v-if="updateallowed && videoobject.length > 0 && role !== 'viewer'" @click.prevent="activateDeleteVideoPopup(video, index)"><trash-icon></trash-icon> </div>
                                            </div>
                                            <div v-if="type === 'event'">{{ video.filename }}</div>
                                            <div v-else-if="video.episode_title">{{index+1}} - {{video.episode_title}}</div>
                                            <div v-else>{{index+1}} - Episode/Program </div>
                                        </li>
                                    </div>

                                </ul>
                                <a href="#" @click.prevent="openDropzoneUploader" id="createNewVideoFromLeft" class="btn btn-primary mt-2" tabindex="-1" v-if="updateallowed && add_another_video_allowed && role !== 'viewer'">
                                    <plus-circle-icon class="svg-24 mr-3"></plus-circle-icon>
                                    <span>Add another video</span>
                                </a>
                            </div>
                            <div class="right omega entrant-right video-entry-right-form col-12" :class="type === 'event' ? 'col-12' : 'col-12 col-md-8 pl-3'">
                                <div v-for="(video, index) in videofiles">
                                    <div class="vid-wrapper" v-if="index === activeVideoIndex">
                                        <div class="form-group-beside row">
                                            <label class="mb-0 pr-1 pull-left displaybloc mt-0" style="line-height: 21px;">Video File Status:</label>
                                            <div class="col-10 col-md-6 input-wrapper">
                                                <div class="col-md-8 pull-left no-gutters pl-0 pt-2">
                                                    <div class="display-inline valign-middle" v-if="!video.id">Select a video file</div>
                                                    <div class="display-inline valign-middle" v-if="video.id && !isadmin" v-text="statusDisplay(video.status)"></div>
                                                    <!-- TODO should we restrict some of the upload/processing related statuses here -->
                                                    <v-select v-if="isadmin && video.id" placeholder="Video File Status" v-model="video.status" :options="videoStatuses"  @input="applyStatusChange(video)" ></v-select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group-beside row video-file-field-fullwrapper">
                                            <label class="mb-0 pr-1 col-2 col-md-6" style="line-height: 21px;">Video File:</label>
                                            <div class="video-file-field-wrapper col-10 col-md-6 input-wrapper d-flex justify-content-between align-items-center" :class="(role === 'admin') ? 'is-admin-viewer': ''" v-if="updateallowed && role !== 'viewer' && video.status != 'Video Approved'">
                                                <div class="filename filename-with-wordbreak pr-2">
                                                    <div>{{ video.filename }}</div>
                                                    <div class="mt-2" v-if="video.asset_id && isadmin">
                                                        <div class="label membership-type pb-0">Brightcove Id</div>
                                                        <div>{{ video.asset_id }}</div>
                                                    </div>
                                                </div>
                                                <div class="button-wrapper file-button-actions w-110minpx text-right" v-if="video.id">
                                                    <a v-if="isadmin" :href="video.signed_url" download class="d-inline-block btn btn-primary btn-padding-smaller mb-1"><download class="svg-black"></download> Download</a>
                                                    <a v-if="type === 'event'" href="#" class="d-inline-block btn btn-delete btn-padding-smaller" @click.prevent="activateDeleteVideoPopup(video, index)"><trash-icon class="svg-black mr-2"></trash-icon> Delete </a>
                                                    <a v-else href="#" class="d-inline-block btn btn-primary btn-padding-smaller" @click.prevent="activateReplaceVideo(video, index)"><upload class="svg-18 mr-2"></upload> Replace</a>
                                                </div>
                                            </div>
                                            <div class="video-file-field-wrapper col-10 col-md-6 input-wrapper d-flex justify-content-between align-items-center" :class="(role === 'admin') ? 'is-admin-viewer': ''" v-if="role === 'viewer' || video.status == 'Video Approved' || !updateallowed">
                                                <div class="filename filename-with-wordbreak pr-2">
                                                    <div>{{ video.filename }}</div>
                                                    <div class="mt-2" v-if="video.asset_id && isadmin">
                                                        <div class="label membership-type pb-0">Brightcove Id</div>
                                                        <div>{{ video.asset_id }}</div>
                                                    </div>
                                                </div>
                                                <div class="button-wrapper file-button-actions w-110minpx text-right" v-if="video.id">
                                                    <a v-if="isadmin" :href="video.signed_url" download class="d-block btn btn-primary btn-padding-smaller">
                                                        <download class="svg-black"></download> Download
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group-beside row" v-if="video.filename">
                                            <label class="mb-0 pr-1 col-2 col-md-6">Preview</label>
                                            <div
                                                v-if="video.is_ready"
                                                class="col-10 col-md-6 mb-1"
                                                @click.prevent="setDropzoneClickIndex(index)"
                                            >
                                                <div class="video-placeholder">
                                                    <video-file :role="role" :filename="video.filename" :vfoid="video.id" :asset_id="video.asset_id" :episode="video.episode_title"></video-file>
                                                </div>
                                            </div>
                                            <div v-else class="col-10 col-md-6 input-wrapper pt-2">
                                                <span class="font-italic">Processing</span>
                                            </div>
                                        </div>

                                        <div class="form-group-beside row notes-video-upload" :class="(!isadmin) ? 'locked' : ''" v-if="isadmin">
                                            <label for="video_notes" class="col-2 col-md-6">Video Notes</label>
                                            <div class="col-10 col-md-6 input-wrapper">
                                                <textarea rows="5" type="text" id="video_notes" class="form-control" v-model="video.notes" @change.prevent="applyChange(video, index)" ></textarea>
                                            </div>
                                        </div>

                                        <div class="form-group pt-0 closed-caption-upload-wrapper" v-if="requiredfields['closedCaptionFieldRequired'] != 'No' && video.id" :class="(video.required && video.required.CCFileUrl) ? 'required' : ''">
                                            <div v-if="updateallowed || video.status == 'Need More Info'" class="form-group-beside row" :class="video.CCFileUrl && video.id ? 'locked' : ''">
                                                <label class="closed-caption-label entry-field__label col-2 col-md-6" :class="video.CCFileUrl && video.id ? 'cc-file-exists ' : 'no-cc-file'" for="closed_caption">
                                                    Closed Captioning Upload <em class="yellow no-style" v-if="requiredfields['closedCaptionFieldRequired'] == 'Required'">*</em>
                                                    <span class="description">
                                                        Format: .vtt or .scc file. Timecodes must start at the 00 hour mark (00:00:00 timecode format), not at the 01 hour mark.
                                                    </span>
                                                </label>
                                                <div class="col-10 col-md-6 input-wrapper" >
                                                    <div id="closed_caption" class="form-control-left-border form-control no-border pl-0 filename word-wrap-break" v-if="video.CCFileUrl ||  video.ccFile">
                                                        <span>{{video.CCFilename}}</span>
                                                        <span class="pl-3 cursor-pointer" @click.prevent="activateDeleteCCFilePopup(video, index)"><trash-icon></trash-icon> </span>
                                                    </div>
                                                    <vue-dropzone v-show="video.ccFile" class="video-placeholder" v-if="!video.CCFileUrl && video.id" ref="myVueDropzone" :id="'dropzone'+index" :options="dropzoneOptions" v-on:vdropzone-success="successCallback" v-on:vdropzone-sending="sendingCallback" v-on:vdropzone-file-added="acceptCallback"></vue-dropzone>
                                                    <a v-if="!video.CCFileUrl && !video.ccFile" href="#" class="btn btn-primary displaybloc" @click.prevent="activateDropzone(index)"><upload></upload> Upload</a>
                                                </div>

                                                <div class="button-wrapper full-width file-button-actions" v-if="isadmin && video.CCFilename">
                                                    <a :href="video.cc_signed_url" download class="d-block float-right mr-3 btn btn-primary btn-padding-smaller mb-1"><download class="svg-black"></download> Download</a>
                                                </div>
                                            </div>
                                            <div v-else class="form-group-beside row">
                                                <label class="closed-caption-label entry-field__label col-2 col-md-6" :class="video.CCFileUrl && video.id ? 'cc-file-exists ' : 'no-cc-file'" for="closed_caption">
                                                    Closed Captioning Upload
                                                    <span class="description">
                                                        Format: .vtt or .scc file. Timecodes must start at the 00 hour mark (00:00:00 timecode format), not at the 01 hour mark.
                                                    </span>
                                                </label>
                                                <div class="col-12 col-md-6">
                                                    <span class="pl-10 word-wrap-break">
                                                        {{ video.CCFilename }}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="form-group-beside row" :class="video.CCFileUrl && video.id ? 'locked' : ''" v-if="isadmin && video.CCFileUrl">
                                                <label :class="video.CCFileUrl && video.id ? 'cc-file-exists ' : 'no-cc-file'" class="closed-caption-label" for="closed_caption">Closed Captioning Status </label>
                                                <div class="col-10 col-md-6 input-wrapper">{{video.cc_status}}</div>
                                            </div>
                                            <div class="form-group-beside row" :class="video.CCFileUrl && video.id ? 'locked' : ''" v-if="isadmin && video.CCFileUrl">
                                                <label :class="video.CCFileUrl && video.id ? 'cc-file-exists ' : 'no-cc-file'" for="closed_caption">Closed Captioning Offset (+/- in seconds)</label>
                                                <div class="col-10 col-md-6 input-wrapper">
                                                    <input type="text" class="form-control" v-model="video.cc_file_offset" />
                                                    <button v-if="video.cc_file_offset || video.cc_file_offset===0" @click.prevent="video.cc_file_offset+=' processing'; applyChange(video)">Process Closed Caption Offset</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group pt-0 audio-description-upload-wrapper" v-if="video.id" :class="(video.required && video.required.audio_description_file_url) ? 'required' : ''">
                                            <div class="form-group-beside row" :class="video.audio_description_file_url && video.id ? 'locked' : ''" v-if="updateallowed || video.status == 'Need More Info'">
                                                <label class="audio-description-label entry-field__label col-2 col-md-6" :class="video.audio_description_file_url && video.id ? 'cc-file-exists ' : 'no-cc-file'" for="audio_description">Audio Description Upload</label>
                                                <div class="col-10 col-md-6 input-wrapper" >
                                                    <div id="audio_description" class="form-control-left-border form-control no-border pl-0 filename word-wrap-break" v-if="video.audio_description_file_url ||  video.audioFile">
                                                        <span>{{video.audio_description_filename}}</span>
                                                        <span class="pl-3 cursor-pointer" @click.prevent="activateDeleteAudioDescriptionFilePopup(video, index)"><trash-icon></trash-icon> </span>
                                                    </div>
                                                    <vue-dropzone v-show="video.audioFile" class="video-placeholder" v-if="!video.audio_description_file_url && video.id" ref="myVueDropzoneAudio" :id="'dropzone'+index +'audio'" :options="dropzoneOptionsAudioDescription" v-on:vdropzone-success="successCallbackAudio" v-on:vdropzone-sending="sendingCallback" v-on:vdropzone-file-added="acceptCallbackAudio"></vue-dropzone>
                                                    <a v-if="!video.audio_description_file_url && !video.audioFile" href="#" class="btn btn-primary displaybloc" @click.prevent="activateDropzoneAudio(index +'audio')"><upload></upload> Upload</a>
                                                </div>

                                                <div class="button-wrapper full-width file-button-actions" v-if="isadmin && video.audio_description_filename">
                                                    <a :href="video.audio_description_signed_url" download class="d-block float-right mr-3 btn btn-primary btn-padding-smaller mb-1"><download class="svg-black"></download> Download</a>
                                                </div>

                                                <div class="form-group-beside-full row w-100" :class="video.audio_description_file_url && video.id ? 'locked' : ''" v-if="isadmin && video.audio_description_file_url">
                                                    <label :class="video.audio_description_file_url && video.id ? 'cc-file-exists ' : 'no-cc-file'" class="closed-caption-label" for="closed_caption">Audio Description Status </label>
                                                    <div class="col-10 col-md-6 input-wrapper">{{video.audio_description_status}}</div>
                                                </div>
                                            </div>
                                            <div class="form-group-beside row" v-else>
                                                <label class="audio-description-label entry-field__label col-2 col-md-6" :class="video.CCFileUrl && video.id ? 'cc-file-exists ' : 'no-cc-file'" for="audio_description">
                                                    Audio Description Upload
                                                </label>
                                                <div class="col-12 col-md-6">
                                                    <span class="pl-10 word-wrap-break">
                                                        {{video.audio_description_filename}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="video-text-info-wrapper" v-if="video.id">
                                            <div class="form-group-beside row" :class="(video.required && video.required.season) ? 'required' : ''" v-if="type != 'event'">
                                                <label for="season" class="col-2 col-md-6">Season Number</label>
                                                <div class="col-10 col-md-6 input-wrapper">
                                                  <v-select  v-if="role !== 'viewer' && (updateallowed || video.status == 'Need More Info')" id="season"  placeholder="Season" v-model="video.season" :options="seasonArray"  @input="applyChange(video, index)" ></v-select>
                                                  <span class="readonly pl-10" v-text="video.season" v-else></span>
                                                </div>
                                            </div>
                                            <div class="form-group-beside row" :class="(video.required && video.required.episode) ? 'required' : ''" v-if="type != 'event'">
                                                <label for="episode" class="col-2 col-md-6">Episode Number</label>
                                                <div class="col-10 col-md-6 input-wrapper">
                                                  <v-select v-if="role !== 'viewer' && (updateallowed || video.status == 'Need More Info')" placeholder="Episode" id="episode" v-model="video.episode" :options="episodeArray"  @input="applyChange(video, index)" ></v-select>
                                                    <span class="readonly pl-10" v-text="video.episode" v-else></span>
                                                </div>
                                            </div>
                                            <div class="form-group-beside row" :class="errorObject['details'] && errorObject['details']['video_file_objects.'+index+'.episode_title'] ? 'required' : ''" v-if="type != 'event'">
                                                <label for="episode_title" class="col-2 col-md-6">Episode Title <em class="yellow no-style" v-if="requiredfields['episodeTitleFieldRequired'] == '1'">*</em></label>
                                                <div class="col-10 col-md-6 input-wrapper">
                                                    <input type="text" ref="episode_title" id="episode_title" autocomplete="off" data-lpignore="true" data-form-type="other" class="form-control" placeholder="If movie/special, repeat program name" v-model="video.episode_title" @blur="titleCaseBlur('episode_title', index, $event)" @keyup="titleCase(video, $event)" @change.prevent="applyChange(video, index)" :readonly="video.readonly_episodetitle && !isadmin" :disabled="!(role !== 'viewer' && (updateallowed || video.status == 'Need More Info'))?true:false">
                                                </div>
                                                <div class="description">
                                                    Program series entrants may upload multiple episodes. Individual achievement category entrants may only upload the one episode chosen for their Emmy entry. Video must be uploaded in its entirety, as originally aired, with the exception of performers who may edit to their appearances only. If this option is chosen, clips from one or more episodes must be edited into one video and put “selected scenes” as the episode title.
                                                </div>
                                            </div>
                                            <div class="form-group-beside row logline-video-upload" :class="(video.readonly_logline) ? 'locked' : errorObject['details'] && errorObject['details']['video_file_objects.'+index+'.logline'] ? 'required' : ''" v-if="type != 'event'">
                                               <label for="log_line" class="col-2 col-md-6">Episode Description  <em class="yellow no-style" v-if="requiredfields['loglineFieldRequired'] == '1'">*</em></label>
                                                <div class="col-10 col-md-6 input-wrapper">
                                                    <textarea rows="5" type="text" id="log_line" class="form-control" v-model="video.logline" @change.prevent="applyChange(video, index)" @keyup="wordCounterDisplay(index)" :readonly="video.readonly_logline && !isadmin" :disabled="!(role !== 'viewer' && (updateallowed || video.status == 'Need More Info'))"></textarea>
                                                    <div class="instructions-text mt-1" v-if="!video.logline">Limited to {{maxWords}} words</div>
                                                    <div class="limited-to-text red" v-if="video.required.loglineNumberOverWordCount > 0">You are <span v-text="video.required.loglineNumberOverWordCount"></span> over the limit.</div>
                                                </div>
                                            </div>
                                            <div class="form-calendar form-group-beside row" :class="(video.required && video.required.hold_date) ? 'required' : ''" v-if="type != 'event'">
                                                <label for="site_live_date" class="col-2 col-md-6">
                                                    <div>Hold this episode until: </div>
                                                    <div class="description">Video will go live asap unless a later date is chosen here</div>
                                                </label>
                                                <div class="col-10 col-md-6 input-wrapper input-wrapper-date">
                                                    <calendar data-toggle class="svg-24 svg-accent d-inline-block"></calendar>
                                                    <flat-pickr id="site_live_date" v-model="video.hold_date" :config="dateConfig"
                                                                placeholder="Select date" v-on:input="applyChange(video, index)" v-if="role !== 'viewer' && (updateallowed || video.status == 'Need More Info')"></flat-pickr>
                                                    <span class="readonly-date" v-text="video.hold_date" v-else></span>
                                                    <div class="input-group-append">
                                                        <a class="date-clear-button" type="button" title="Clear" data-clear v-if="role !== 'viewer'">
                                                            <i class="close-icon"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <modal v-if="modalTrigger" @close="modalTrigger = false">
                            <h3 slot="header">Select Video File</h3>
                            <div class="content" slot="body">
                                <div class="existing-videos-wrapper">
                                  <v-select placeholder="Select Video" v-model="exisitingVideoSelected" label="text" :reduce="text => text.id" :options="existingvideos"></v-select>
                                </div>
                            </div>
                            <div class="form-group-submit clearfix" slot="footer">
                                <button class="btn btn-cancel pull-left" @click.prevent="modalTrigger = false">Close</button>
                                <button class="pull-right btn btn-primary" @click.prevent="saveClonedVideo">Add video</button>
                            </div>
                        </modal>

                        <modal v-if="modalTriggerConfirmDelete" @close="modalTriggerConfirmDelete = false">
                            <h3 slot="header">Removing Video File</h3>
                            <div class="content" slot="body">
                                <div v-if="videoModalDelete.filename">Are you sure you want to remove the file {{videoModalDelete.filename}}? </div>
                                <div v-else>Are you sure you want to remove this item?</div>
                            </div>
                            <div class="form-group-submit clearfix" slot="footer">
                                <button class="btn btn-cancel pull-left" @click.prevent="modalTriggerConfirmDelete = false">Cancel</button>
                                <button class="pull-right btn btn-primary" @click.prevent="deleteVideo(videoModalDelete.id)">Yes, Remove</button>
                            </div>
                        </modal>

                        <modal v-if="modalTriggerConfirmCCRemove" @close="modalTriggerConfirmCCRemove = false">
                            <h3 slot="header">Removing Closed Captioning File</h3>
                            <div class="content" slot="body">
                                <div v-if="videoModalDelete.CCFilename">Are you sure you want to remove the file {{videoModalDelete.CCFilename}}?</div>
                                <div v-else>Are you sure you want to remove this item?</div>
                            </div>
                            <div class="form-group-submit clearfix" slot="footer">
                                <button class="btn btn-cancel pull-left" @click.prevent="modalTriggerConfirmCCRemove = false">Cancel</button>
                                <button class="pull-right btn btn-primary" @click.prevent="removeCCFile(videoModalDelete.id)">Yes, Remove</button>
                            </div>
                        </modal>

                        <modal v-if="modalTriggerConfirmAudioDescriptionRemove" @close="modalTriggerConfirmAudioDescriptionRemove = false">
                            <h3 slot="header">Removing Audio Description File</h3>
                            <div class="content" slot="body">
                                <div v-if="videoModalDelete.audio_description_filename">Are you sure you want to remove the file {{videoModalDelete.audio_description_filename}}?</div>
                                <div v-else>Are you sure you want to remove this item?</div>
                            </div>
                            <div class="form-group-submit clearfix" slot="footer">
                                <button class="btn btn-cancel pull-left" @click.prevent="modalTriggerConfirmAudioDescriptionRemove = false">Cancel</button>
                                <button class="pull-right btn btn-primary" @click.prevent="removeAudioDescriptionFile(videoModalDelete.id)">Yes, Remove</button>
                            </div>
                        </modal>

                        <modal v-if="modalTriggerThirdPartySubmit" @close="modalTriggerThirdPartySubmit = false">
                            <h3 slot="header"></h3>
                            <div class="content" slot="body">
                                <h3>Thank you, the video(s) have been submitted.</h3>
                            </div>
                            <div class="form-group-submit clearfix" slot="footer">
                                <button class="btn btn-primary btn-transparent pull-left" @click.prevent="modalTriggerThirdPartySubmit = false">Close</button>
                            </div>
                        </modal>

                    </div>
                </div>
            </div>
        </div>
        <div class="mt-4 pull-right">
            <button
                v-if="is3rd_party && role !== 'viewer'"
                type="submit"
                @click.prevent="thirdPartySubmit"
                class="btn btn-primary btn-step-next submit-text"
                :disabled="!videofiles.length"
            >
                <save-icon></save-icon>
                Submit Video
            </button>
        </div>
    </div>
</template>

<script>
const VIDEO_STATUSES = [
    'Upload Failed',
    'Upload Successful',
    'Processing',
    'Processing Failed',
    'Under Review',
    'Need More Info',
    'Video Approved',
];

import modal from './Modal.vue';
import videoFile from './Videofile.vue';
import Select2 from 'v-select2-component';
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import PlusCircleIcon from "@/Icons/plus-circle.vue";
import Download from "@/Icons/download.vue";
import Upload from "@/Icons/upload.vue";
import Calendar from "@/Icons/calendar.vue";
import SaveIcon from "@/Icons/save.vue";
import TrashIcon from "@/Icons/trash.vue";
import Collapse from "@/Icons/collapse.vue";

export default {
    name: "VideoUploadComponent",
    components: {
        Collapse,
        SaveIcon, Calendar, Upload, Download, PlusCircleIcon, TrashIcon, modal, Select2, vueDropzone, videoFile},
    props: {
        videofilestatuses: Array,
        initialdata : Array,
        videoobject : Array,
        entryid: Number,
        isadmin: {
            type: Boolean,
            default: false,
        },
        submitterid: Number,
        nominee_video_status: String,
        captioning: String,
        bucketname: String,
        displayName: String,
        add_another_video_allowed: {
            type: Boolean,
            default: true,
        },
        role: String,
        is3rd_party: {
            type: Boolean,
            default: false,
        },
        hold_until_min_date: String,
        hold_until_max_date: String,
        type: String,
        machine_name: String,
        errorObject: {
            type: Object,
            default: {
                'details':  ''
            },
        },
        updateallowed: {
            type: Boolean,
            default: true,
        },
        requiredVideoUploads: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            savingData: false,
            video_upload_in_progress: false,
            videoStatuses: VIDEO_STATUSES,
            video_status_for_editing: ['Upload Failed', 'Processing Failed', 'Need More Info'],
            video_objects: this.videoobject,
            maxvideos: 20,
            episodeArray: Array.from({length: 100}, (_, index) => index + 1),
            seasonArray: Array.from({length: 100}, (_, index) => index + 1),
            modalTrigger: false,
            modalTriggerConfirmDelete: false,
            modalTriggerConfirmCCRemove: false,
            modalTriggerConfirmAudioDescriptionRemove: false,
            modalTriggerConfirmSubmit: false,
            modalTriggerThirdPartySubmit:false,
            entryVideoStatus: this.nominee_video_status,
            errorCodes: [401, 403, 419],
            requiredfields: {
                'episodeTitleFieldRequired': 1,
                'loglineFieldRequired': 1,
            },
            confirmSubmitClicked: false,
            buttonDisabled: true,
            videoModalDelete: '',
            videostatus: '',
            videofiles: [],
            existingvideos: [],
            exisitingVideoSelected: '',
            activeVideoIndex: 0,
            maxWords: 50,
            showReplaceProgressBar: false,
            dateConfig: {
                wrap: true,
                dateFormat: 'm-d-Y',
                minDate: this.hold_until_min_date,
                maxDate: this.hold_until_max_date,
            },
            videoObjectDetails: {
                'id': '',
                'filename': '',
                'CCFilename': '',
                'CCFileUrl': '',
                'audio_description_filename': false,
                'audio_description_file_url': false,
                'audio_description_status': '',
                'episode': '',
                'season': '',
                'episode_title': '',
                'logline': '',
                'required': {
                    'CCFileUrl': false,
                    'audio_description_file_url': false,
                    'episode': false,
                    'season': false,
                    'episode_title': false,
                    'logline': false,
                    'loglineNumberOverWordCount': 0,
                }
            },
            dropzoneOptions: {
                thumbnailWidth: 150,
                autoQueue: false,
                // The URL will be changed for each new file being processing
                url: '/',
                maxFilesize: 300000, // 300GB
                timeout: 172800000, // 48 hours in miliseconds
                // Since we're going to do a `PUT` upload to S3 directly
                method: 'put',
                dictDefaultMessage: '',
                // Hijack the xhr.send since Dropzone always upload file by using formData
                // ref: https://github.com/danialfarid/ng-file-upload/issues/743
                // sending (file, xhr) {
                //     let _send = xhr.send
                //     xhr.send = () => {
                //         _send.call(xhr, file)
                //     }
                // },
                // Upload one file at a time since we're using the S3 pre-signed URL scenario
                parallelUploads: 10,
                uploadMultiple: false,
                // Content-Type should be included, otherwise you'll get a signature
                // mismatch error from S3. We're going to update this for each file.
                header: '',
                // We're going to process each file manually (see `accept` below)
                autoProcessQueue: true,
                acceptedFiles: '.vtt,.scc',
                // accept: this.acceptCallback, // use our custom acceptCallback
                // canceled : this.abortUpload,
                // complete: this.completeUpload,
                sending: function(file, xhr) {
                    var _send = xhr.send;
                    xhr.send = function() {
                        _send.call(xhr, file);
                    }
                }
            },
            dropzoneOptionsAudioDescription: {
                autoQueue: false,
                // The URL will be changed for each new file being processing
                url: '/',
                maxFilesize: 300000, // 300GB
                timeout: 172800000, // 48 hours in miliseconds
                // Since we're going to do a `PUT` upload to S3 directly
                method: 'put',
                dictDefaultMessage: '',
                // Hijack the xhr.send since Dropzone always upload file by using formData
                // ref: https://github.com/danialfarid/ng-file-upload/issues/743
                // sending (file, xhr) {
                //     let _send = xhr.send
                //     xhr.send = () => {
                //         _send.call(xhr, file)
                //     }
                // },
                // Upload one file at a time since we're using the S3 pre-signed URL scenario
                parallelUploads: 10,
                uploadMultiple: false,
                // Content-Type should be included, otherwise you'll get a signature
                // mismatch error from S3. We're going to update this for each file.
                header: '',
                // We're going to process each file manually (see `accept` below)
                autoProcessQueue: true,
                acceptedFiles: '.wav',
                // accept: this.acceptCallback, // use our custom acceptCallback
                // canceled : this.abortUpload,
                // complete: this.completeUpload,
                sending: function(file, xhr) {
                    var _send = xhr.send;
                    xhr.send = function() {
                        _send.call(xhr, file);
                    }
                }
            },
            dropzoneOptionsVideo: {
                thumbnailWidth: 150,
                autoQueue: false,
                // The URL will be changed for each new file being processing
                url: '/',
                maxFilesize: 300000, // 300GB
                timeout: 172800000, // 48 hours in miliseconds
                // Since we're going to do a `PUT` upload to S3 directly
                method: 'put',
                dictDefaultMessage: 'Browse for Videos',
                // Upload one file at a time since we're using the S3 pre-signed URL scenario
                parallelUploads: 10,
                uploadMultiple: true,
                // Content-Type should be included, otherwise you'll get a signature
                // mismatch error from S3. We're going to update this for each file.
                header: '',
                // We're going to process each file manually (see `accept` below)
                autoProcessQueue: true,
                acceptedFiles: '.mp4,.mov',
                // accept: this.acceptCallback, // use our custom acceptCallback
                // canceled : this.abortUpload,
                complete: this.successVideoCallback,

            },
            dropzoneReplacementOptionsVideo: {
                thumbnailWidth: 150,
                autoQueue: false,
                // The URL will be changed for each new file being processing
                url: '/',
                maxFilesize: 300000, // 300GB
                timeout: 172800000, // 48 hours in miliseconds
                // Since we're going to do a `PUT` upload to S3 directly
                method: 'put',
                dictDefaultMessage: 'Browse for Videos',
                // Upload one file at a time since we're using the S3 pre-signed URL scenario
                parallelUploads: 10,
                uploadMultiple: true,
                // Content-Type should be included, otherwise you'll get a signature
                // mismatch error from S3. We're going to update this for each file.
                header: '',
                // We're going to process each file manually (see `accept` below)
                autoProcessQueue: true,
                acceptedFiles: '.mp4,.mov',
                // accept: this.acceptCallback, // use our custom acceptCallback
                // canceled : this.abortUpload,
                complete: this.successVideoReplaceCallback,
                sending: function(file, xhr) {
                    // console.log('sending', file);
                    file.s3upload.send(function(err, data) {
                        if (err) {
                            theDropzone.emit("error", file, err.message);
                        } else {
                            theDropzone.emit("complete", file);
                        }
                    });

                }
            }
        }
    },
    emits: [
        'upload-started',
        'upload-finished',
        'savingVideoData',
    ],
    created: function() {

    },
    mounted () {
        AWS.config.region = 'us-west-2'; // Region
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: 'us-west-2:f916f8a9-1780-4953-a74b-75d5607638af',
        });
        this.videofiles = this.setInitialVideoFile();
        let app = this;
        if (this.$refs.videoDropzone && this.$refs.videoDropzone.dropzone) {
            this.$refs.videoDropzone.dropzone.uploadFiles = function (files) {
                // console.log('[uploadFiles] Override', files);
                for (var j = 0; j < files.length; j++) {
                    var file = files[j];
                    app.sendVideoFile(file, app.$refs.videoDropzone.dropzone);
                }
            }
        }
        if (this.$refs.videoDropzoneReplace && this.$refs.videoDropzoneReplace.dropzone) {
            this.$refs.videoDropzoneReplace.dropzone.uploadFiles = function (files) {
                // console.log('[uploadFiles] Override', files);
                for (var j = 0; j < files.length; j++) {
                    var file = files[j];
                    app.sendVideoFile(file, app.$refs.videoDropzoneReplace.dropzone);
                }
            }
        }

    },
    methods: {
        sendVideoFile(file, dzelement) {
            this.$emit('upload-started');
            file.s3upload.send((err, data) => {
                if (err) {
                    console.log(err.message);
                    dzelement.emit("error", file, err.message);
                    this.$emit('upload-finished');
                } else {
                    dzelement.emit("complete", file);
                    this.$emit('upload-finished');
                }
            });

            // listen to the AWS httpUploadProgress event, and emit an equivalent Dropzone event
            file.s3upload.on('httpUploadProgress', function(progress) {
                if (progress.total) {
                    var percent = ((progress.loaded * 100) / progress.total);
                    dzelement.emit('uploadprogress', file, percent, progress.loaded);
                }
            });
        },
        thirdPartySubmit(){
            const token = uploaderToken; // var defined in layouts/base.blade.php

            this.modalTriggerThirdPartySubmit = true;

            axios
                .post(`/videouploadcomplete/${token}`)
                .then(() => {})
                .catch(err => {
                    if (err.response && this.errorCodes.includes(err.response.status)) {
                        window.location.reload();
                    }
                    this.modalTriggerThirdPartySubmit = false;
                    alert('There was a problem completing your submission. Please notify the submitter.');
                    console.log('This is the error returned', err);
                });

        },
        activateReplaceVideo(video, index) {
            // TBD still
            // console.log('lets replace a video', video);
            this.$refs.videoDropzoneReplace.$el.click();
        },
        statusDisplay(inputStatus) {
            switch (inputStatus) {
                case 'Upload Failed':
                    return 'Upload Failed';
                case 'Uploading':
                    return 'Uploading...';
                case 'Need More Info':
                    return 'Needs More Information';
                case 'Canceled':
                    return 'Canceled';
                case 'Video Approved':
                    return 'Video Approved';
                // case 'Processing Failed':
                //     return 'Processing Failed';
                default:
                    return 'Under Review';
            }
        },
        setInitialVideoFile: function() {
            let app = this;
            let videoF = [];

            // inject user data in
            app.video_objects.forEach(function(video,index) {
                video.required = {
                    'CCFileUrl': false,
                    'audio_description_file_url': false,
                    'episode': false,
                    'season': false,
                    'episode_title': false,
                    'logline': false,
                    'loglineNumberOverWordCount': 0,
                };
                videoF[index] = video;
            });

            // make sure none of them are undefined
            var filtered = videoF.filter(function(item) {
                return item !== undefined;
            });
            return filtered
        },
        updateWeights: function() {
            const weightIdValues = this.videofiles.map(function(videoFile, index){
                return {
                    'id': videoFile.id,
                    'weight': index
                }
            });

            axios
                .post(
                    `/video/updateweights`,
                    {
                        order_id: this.entryid,
                        weights: weightIdValues
                    }
                )
                .then(() => {})
                .catch(err => {
                    if (err.response && this.errorCodes.includes(err.response.status)) {
                        window.location.reload();
                        return;
                    }
                    alert('Error while re-ordering videos.');
                    console.log('This is the error returned', err);
                });
        },
        sendUpdatedObject: function(object_data) {
            const video = this.videofiles[this.activeVideoIndex];

            this.$emit('savingVideoData', true);

            axios
                .post(`/video/update/${video.id}`, object_data)
                .then(response => {
                    this.modalTrigger = false;
                    if (this.confirmSubmitClicked) {
                        this.underlineFields();
                    }
                    // update statuses
                    if (response.data.object) {
                        if (response.data.object.status) {
                            video.status = response.data.object.status;
                        }
                        if (response.data.object.cc_status) {
                            video.cc_status = response.data.object.cc_status;
                        }
                        if (response.data.object.audio_description_status) {
                            video.audio_description_status = response.data.object.audio_description_status;
                        }
                        if (response.data.object.is_ready) {
                            video.is_ready = response.data.object.is_ready;
                        }
                    }
                    this.savingData = false;
                    this.$emit('savingVideoData', false);
                })
                .catch(err => {
                    if(err.response && this.errorCodes.includes(err.response.status)) {
                        window.location.reload();
                    }
                    alert('There was an error updating your video, please try again.')
                    console.log('This is the error returned', err);
                    this.savingData = false;
                    this.$emit('savingVideoData', false);
                });
        },
        applyStatusChange: function(video) {
            let app = this;
            this.sendUpdatedObject({
                id: video.id,
                order_id: app.entryid,
                status: video.status,
            });
        },
        applyChange: function(video, index) {
            let app = this;
            if(app.savingData) {
                return;
            }
            app.savingData = true;

            if(video.episode_title && app.errorObject['details']['video_file_objects.'+index+'.episode_title']) {
                app.errorObject['details']['video_file_objects.'+index+'.episode_title'] = '';
            }
            if(video.logline && app.errorObject['details']['video_file_objects.'+index+'.logline']) {
                app.errorObject['details']['video_file_objects.'+index+'.logline'] = '';
            }

            this.sendUpdatedObject({
                id: video.id,
                order_id: app.entryid,
                filename: video.filename,
                season: video.season,
                episode: video.episode,
                episode_title: video.episode_title,
                weight: video.weight,
                hold_date: video.hold_date,
                logline: video.logline,
                notes: video.notes,
                cc_file_offset: video.cc_file_offset,
            });
        },
        videoActive: function(index) {
            let app = this;
            app.activeVideoIndex = index;
        },
        addNewVideo: function() {
            let app = this;
            app.activeVideoIndex = app.videofiles.length;
            app.videofiles.push({
                'id': '',
                'filename': '',
                'episode': '',
                'season': '',
                'episode_title': '',
                'logline': '',
                'required': {
                    'episode': false,
                    'audio_description_file_url': false,
                    'season': false,
                    'episode_title': false,
                    'logline': false,
                    'loglineNumberOverWordCount': 0,
                }
            })
        },
        selectFileModal: function(index) {
            // This looks up all the current videos the user has
            let app = this;
            app.activeVideoIndex = index;
            window.axios(
                {
                    method: 'GET',
                    url: `/api/upload/user_vfos/${app.submitterid}/${app.entryid}`,
                })
                .then(function(response) {
                    app.existingvideos = response.data;
                    // This adds the filename as a 'text' value for the select2 vue component
                    app.existingvideos.forEach(function(i,v) {
                        app.existingvideos[v].text = i.filename + ' - '+i.created_display
                    });
                    app.modalTrigger = true;
                }).catch(function (err) {
                    if(err.response && app.errorCodes.includes(err.response.status)) {
                        window.location.reload();
                    }
                    console.log('This is the error returned', err);
            });
        },
        saveClonedVideo: function() {
            let app = this;

            // This finds the file details selected
            var selectedFile = app.existingvideos.filter(function(number) {
                return number.id == app.exisitingVideoSelected;
            });
            app.videofiles[app.activeVideoIndex].filename = selectedFile[0].filename;
            app.videofiles[app.activeVideoIndex].CCFilename = selectedFile[0].CCFilename;
            app.videofiles[app.activeVideoIndex].CCFileUrl = selectedFile[0].CCFileUrl;
            app.videofiles[app.activeVideoIndex].status = selectedFile[0].status;
            app.modalTrigger = false;
            var weight = app.activeVideoIndex;

            // console.log(selectedFile);
            window.axios(
                {
                    method: 'POST',
                    data: {
                        weight: weight
                    },
                    url: '/video/clone/'+selectedFile[0].id+'/'+app.entryid
                })
                .then(function(response) {
                    //console.log(response, 'this is returned from the clone');
                    // Then save the video id in the Axios return .... like the following
                    if (selectedFile[0].id == response.data) {
                        app.videofiles[app.activeVideoIndex].id = selectedFile[0].id;
                        app.applyChange(app.videofiles[app.activeVideoIndex], app.activeVideoIndex);
                    } else {
                        alert('There was a problem attaching the video');
                    }
                }).catch(function (err) {
                    if(err.response && app.errorCodes.includes(err.response.status)) {
                        window.location.reload();
                    }
                    console.log('This is the error returned', err);
                    alert('There was a problem attaching the video');
            });

        },
        wordCounterDisplay: function(index) {
            let app = this;
            let text = app.videofiles[index].logline;
            if(text != null) {
                let numberWords = window.countWords(text);
                if (numberWords > app.maxWords) {
                    app.videofiles[index].required.loglineNumberOverWordCount = numberWords - app.maxWords;
                } else {
                    app.videofiles[index].required.loglineNumberOverWordCount = 0;
                }
            }
        },
        sendVideoFileStatus(file) {
            let app = this;
            let actionUrl = '/video/create';
            // console.log('creating file status', file);
            axios.post(actionUrl, {
                    order_id: app.entryid,
                    machine_name: app.machine_name,
                    submitter_id: app.submitterid,
                    type: app.type,
                    video_file_url: file.s3key,
                    video_filename: file.name
                })
                .then(function(response) {
                    // console.log('created video object',response);
                    // push in the videofile object based on the response
                    app.video_objects.push(response.data.vfo);
                    app.videofiles = app.setInitialVideoFile();
                    app.$refs.videoDropzone.dropzone.removeFile(file);
                    app.video_upload_in_progress = false;
                }).catch(function (err) {
                    if(err.response && app.errorCodes.includes(err.response.status)) {
                        window.location.reload();
                    }
                    console.log('VideoFileUpload error', err)
                });
        },
        sendUpdatedVideoFileStatus(file) {
            let app = this;
            let vfo_id = app.videofiles[app.activeVideoIndex].id;
            let actionUrl = `/video/update/${vfo_id}`;
            // console.log('send updated file status', file);
            axios.post(actionUrl, {
                id: vfo_id,
                order_id: app.entryid,
                video_file_url: file.s3key,
                video_filename: file.name
            })
                .then(function(response) {
                    app.videofiles[app.activeVideoIndex].filename = file.name;
                    app.videofiles[app.activeVideoIndex].S3key = file.s3key;
                }).catch(function (err) {
                    if(err.response && app.errorCodes.includes(err.response.status)) {
                        window.location.reload();
                    }
                    console.log('CC error', err)
                    alert('error posting video update')
            });
        },
        sendFileStatus(file) {
            let app = this;
            let vfo_id = app.videofiles[app.activeVideoIndex].id;
            let actionUrl = `/video/update/${vfo_id}`;
            // console.log('send file status', file);
            axios.post(actionUrl, {
                    id: vfo_id,
                    order_id: app.entryid,
                    CCFileUrl: file.s3key,
                    CCFilename: file.name
                })
                .then(function(response) {
                    app.videofiles[app.activeVideoIndex].CCFilename = file.name;
                    app.videofiles[app.activeVideoIndex].CCFileUrl = file.s3key;
                    app.videofiles[app.activeVideoIndex].ccFile = true;
                    app.videofiles[app.activeVideoIndex].cc_status = 'processing';
                    app.videofiles[app.activeVideoIndex].is_ready = false;
                }).catch(function (err) {
                    if(err.response && app.errorCodes.includes(err.response.status)) {
                        window.location.reload();
                    }
                    console.log('CC error', err)
                    app.videofiles[app.activeVideoIndex].CCFileUrl = '';
            });
        },
        sendAudioFileStatus(file) {
            let app = this;
            let vfo_id = app.videofiles[app.activeVideoIndex].id;
            let actionUrl = `/video/update/${vfo_id}`;
            // console.log('send file status', file);
            axios.post(actionUrl, {
                id: vfo_id,
                order_id: app.entryid,
                audio_description_file_url: file.s3key,
                audio_description_filename: file.name
            })
                .then(function(response) {
                    app.videofiles[app.activeVideoIndex].audio_description_filename = file.name;
                    app.videofiles[app.activeVideoIndex].audio_description_file_url = file.s3key;
                    app.videofiles[app.activeVideoIndex].audioFile = true;
                    app.videofiles[app.activeVideoIndex].audio_description_status = 'processing';
                    app.videofiles[app.activeVideoIndex].is_ready = false;
                }).catch(function (err) {
                    if(err.response && app.errorCodes.includes(err.response.status)) {
                        window.location.reload();
                    }
                    console.log('CC error', err)
                    app.videofiles[app.activeVideoIndex].audio_description_file_url = '';
            });
        },
        cancelAllUploads() {
            console.log('cancel');
            this.dropzone.removeAllFiles(true);
        },
        abortUpload(file) {
            console.log('Abort Upload');
            if (file.s3upload) file.s3upload.abort();
            this.sendFileStatus(file);
        },
        successVideoCallback(file){
            if (file.status === "canceled") {
                return;
            }
            // console.log('success Video', file);

            this.sendVideoFileStatus(file);
        },
        successVideoReplaceCallback(file) {
            // console.log('success REPLACEMENT Video', file);
            if (file.status === "canceled") {
                return;
            }
            this.showReplaceProgressBar = false;
            this.sendUpdatedVideoFileStatus(file);
        },
        successCallback(file, response){
            // console.log('success', file);
            // console.log(response);

            this.sendFileStatus(file);
        },
        successCallbackAudio(file, response){
            // console.log('success', file);
            // console.log(response);

            this.sendAudioFileStatus(file);
        },
        sendingCallback(file, xhr, formData) {
            console.log('sending');
            xhr.timeout = 99999999;
        },
        acceptVideoCallback(file, dzone) {
            console.log('acceptVideoCallback method');
            this.video_upload_in_progress = true;
            let vm = this;
            var ext = file.name.slice(file.name.lastIndexOf('.') + 1);
            var filename = `FYC/2025/videos/video_${vm.entryid}_${vm.submitterid}_${Date.now()}.${ext}`;
            file.s3key = filename;
            var params = {Bucket: vm.bucketname, Key: filename, Body: file};
            // add an S3 managed upload instance to the file
            file.s3upload = new AWS.S3.ManagedUpload({params: params});
            //done();
            vm.$refs.videoDropzone.dropzone.processFile(file);
        },
        acceptReplaceVideoCallback(file, dzone) {
            console.log('NEW acceptVideoCallback method');
            this.showReplaceProgressBar = true;
            let vm = this;
            var ext = file.name.slice(file.name.lastIndexOf('.') + 1);
            var filename = `FYC/2025/videos/video_${vm.entryid}_${vm.submitterid}_${Date.now()}.${ext}`;
            file.s3key = filename;
            var params = {Bucket: vm.bucketname, Key: filename, Body: file};
            // add an S3 managed upload instance to the file
            file.s3upload = new AWS.S3.ManagedUpload({params: params});
            //done();
            vm.$refs.videoDropzoneReplace.dropzone.processFile(file);
        },
        acceptCallback(file, dzone) {
            // console.log('acceptCallback method');
            let vm = this;
            var ext = file.name.slice(file.name.lastIndexOf('.') + 1);
            var filename = `2025/captions/captionfile_${vm.entryid}_${vm.submitterid}_${Date.now()}.${ext}`;
            //console.log(this);

            // get signed url for upload
            let app = this;
            axios.get('/api/signed_url', {
                    params: {
                        filename: filename,
                        filetype: file.type
                    }
                })
                .then(function(response) {
                    // console.log(response.data.signed_url);
                    file.s3key = `FYC/${filename}`;
                    app.$refs.myVueDropzone[0].dropzone.URL = response.data.signed_url;
                    app.$refs.myVueDropzone[0].dropzone.options.url = response.data.signed_url;
                    // console.log(app.$refs.myVueDropzone[0].dropzone);
                    app.$refs.myVueDropzone[0].dropzone.processFile(file);
                }).catch(function (err) {
                    if(err.response && app.errorCodes.includes(err.response.status)) {
                        window.location.reload();
                    }
                });


            // console.log(file, 'file');



            // options for the managed upload for this accepted file
            // define the bucket, and the S3 key the file will be stored as

            //this.sendFileStatus(file);
            // automatically start the uploads (comment it out if you want to use the start upload button)
            //setTimeout(() => vm.dropzone.processFile(file))
        },

        acceptCallbackAudio(file, dzone) {
            console.log('acceptCallback method');
            let vm = this;
            var ext = file.name.substr(file.name.lastIndexOf('.') + 1);
            var filename = `2025/audio_description/audio_description_${vm.entryid}_${vm.submitterid}_${Date.now()}.${ext}`;
            //console.log(this);

            // get signed url for upload
            let app = this;
            axios.get('/api/signed_url', {
                params: {
                    filename: filename,
                    filetype: file.type
                }
            })
                .then(function(response) {
                    // console.log(response.data.signed_url);
                    file.s3key = `FYC/${filename}`;
                    app.$refs.myVueDropzoneAudio[0].dropzone.URL = response.data.signed_url;
                    app.$refs.myVueDropzoneAudio[0].dropzone.options.url = response.data.signed_url;
                    // console.log(app.$refs.myVueDropzoneAudio[0].dropzone);
                    app.$refs.myVueDropzoneAudio[0].dropzone.processFile(file);
                }).catch(function (err) {
                    if(err.response && app.errorCodes.includes(err.response.status)) {
                        window.location.reload();
                    }
                });


            // console.log(file, 'file');



            // options for the managed upload for this accepted file
            // define the bucket, and the S3 key the file will be stored as

            //this.sendFileStatus(file);
            // automatically start the uploads (comment it out if you want to use the start upload button)
            //setTimeout(() => vm.dropzone.processFile(file))
        },
        sendFile(file, dzelement) {
            console.log('sending file through AWS sdk');
            file.s3upload.send(function(err, data) {
                if (err) {
                    console.log(err.message);
                    dzelement.emit("error", file, err.message);
                } else {
                    console.log('complete');
                    file.status = Dropzone.SUCCESS;
                    dzelement.emit("success", file, 'success', null);
                    dzelement.emit("complete", file);

                }
            });

            // listen to the AWS httpUploadProgress event, and emit an equivalent Dropzone event
            file.s3upload.on('httpUploadProgress', function(progress) {
                if (progress.total) {
                    var percent = ((progress.loaded * 100) / progress.total);
                    dzelement.emit('uploadprogress', file, percent, progress.loaded);
                }
            });
        },
        activateDropzone: function(index) {
          this.$refs['myVueDropzone'][0].$el.click();
        },
        activateDropzoneAudio: function(index) {
            this.$refs['myVueDropzoneAudio'][0].$el.click();
        },
        activateDeleteVideoPopup(video, index) {
            let app = this;
            app.modalTriggerConfirmDelete = true;
            app.videoModalDelete = video;
            app.activeVideoIndex = index;
        },
        deleteVideo: function(videoid) {
            let app = this;
            if(!videoid) {
                app.videofiles.splice(app.activeVideoIndex, 1);
                app.modalTriggerConfirmDelete = false;
                if(app.activeVideoIndex != 0) {
                    app.activeVideoIndex = app.activeVideoIndex-1;
                } else {
                    app.activeVideoIndex = 0;
                }
                return;
            }

            let indexlist= [];
            app.videofiles.splice(app.activeVideoIndex, 1);
            app.videofiles.forEach(function(video, index){
                indexlist[index] = video.id;
            });

            let actionUrl = `/video/detach`;
            window.axios(
                {
                    method: 'POST',
                    data: {
                        'video_id':videoid,
                        'token': false,
                        'indexlist' : indexlist,
                    },
                    url: actionUrl,
                }).then(function(response) {
                app.modalTriggerConfirmDelete = false;
                //app.videofiles.splice(app.activeVideoIndex, 1)
                app.activeVideoIndex = 0;
                window.location.reload();
            }).catch(function (err) {
                if(err.response && app.errorCodes.includes(err.response.status)) {
                    window.location.reload();
                }
                console.log('This is the error returned', err);
            });
        },
        setDropzoneClickIndex: function(index) {
            this.dropzoneIndex = index;
        },
        underlineFields: function() {
            let app = this;
            let submitFormBlock = false;
            app.videofiles.forEach(function(i,v) {
                if(!i.CCFileUrl && app.requiredfields['closedCaptionFieldRequired'] == 'Required') {
                    app.videofiles[v].required.CCFileUrl = true;
                    submitFormBlock = true;
                } else {app.videofiles[v].required.CCFileUrl = false;}
                if(!i.episode_title && app.requiredfields['episodeTitleFieldRequired'] == '1') {
                    app.videofiles[v].required.episode_title = true;
                    submitFormBlock = true;
                } else {app.videofiles[v].required.episode_title = false;}

                app.wordCounterDisplay(v)
                if (!i.logline && app.requiredfields['loglineFieldRequired'] == '1' || app.videofiles[v].required.loglineNumberOverWordCount > 0) {
                    app.videofiles[v].required.logline = true;
                    submitFormBlock = true;
                } else {app.videofiles[v].required.logline = false;}
            });
            return submitFormBlock;
        },
        leftSidebarClass: function(index, video) {
            let app = this;
            let sidebarClass = '';
            if(index == app.activeVideoIndex) {
                sidebarClass = 'active';
            }

            if(Object.values(video.required).includes(true)) {
                sidebarClass += ' required-video';
            }

            if( app.errorObject['details']['video_file_objects.'+index+'.logline'] ||
                app.errorObject['details']['video_file_objects.'+index+'.episode_title'] ||
                app.errorObject['details']['video_file_objects.'+index+'.filename']) {
                sidebarClass += ' required-video';
            }

            return sidebarClass;
        },
        activateDeleteCCFilePopup(video, index) {
            let app = this;
            app.modalTriggerConfirmCCRemove = true;
            app.videoModalDelete = video;
            app.activeVideoIndex = index;
        },
        activateDeleteAudioDescriptionFilePopup(video, index) {
            let app = this;
            app.modalTriggerConfirmAudioDescriptionRemove = true;
            app.videoModalDelete = video;
            app.activeVideoIndex = index;
        },
        removeCCFile: function(vid) {
            let app = this;
            let vfo_id = app.videofiles[app.activeVideoIndex].id;
            let actionUrl = '/video/update/' + vfo_id;

            window.axios(
                {
                    method: 'POST',
                    url: actionUrl,
                    data: {
                        id: vid,
                        order_id: app.entryid,
                        CCFileUrl: '',
                        CCFilename: '',
                        cc_status: '',
                        cc_file_offset: ''
                    }
                }).then(function(response) {
                app.videofiles[app.activeVideoIndex].ccFile = '';
                app.videofiles[app.activeVideoIndex].CCFileUrl = '';
                app.videofiles[app.activeVideoIndex].CCFilename = '';
                app.videofiles[app.activeVideoIndex].cc_status = '';
                app.videofiles[app.activeVideoIndex].cc_file_offset = '';

                app.modalTriggerConfirmCCRemove = false;
            }).catch(function (err) {
                if(err.response && app.errorCodes.includes(err.response.status)) {
                    window.location.reload();
                }
                console.log('This is the error returned', err);
            });
        },
        removeAudioDescriptionFile: function(vid) {
            let app = this;
            let vfo_id = app.videofiles[app.activeVideoIndex].id;
            let actionUrl = '/video/update/' + vfo_id;

            window.axios(
                {
                    method: 'POST',
                    url: actionUrl,
                    data: {
                        id: vid,
                        order_id: app.entryid,
                        audio_description_file_url: '',
                        audio_description_filename: '',
                    }
                }).then(function(response) {
                app.videofiles[app.activeVideoIndex].audioFile = '';
                app.videofiles[app.activeVideoIndex].audio_description_file_url = '';
                app.videofiles[app.activeVideoIndex].audio_description_filename = '';
                app.modalTriggerConfirmAudioDescriptionRemove = false;
            }).catch(function (err) {
                if(err.response && app.errorCodes.includes(err.response.status)) {
                    window.location.reload();
                }
                console.log('This is the err returned', err);
            });
        },
        openDropzoneUploader: function() {
            if (this.$refs.videoDropzone) {
                this.$refs.videoDropzone.$el.click();
            }
        },
        titleCaseBlur: function (varname, indexval, evt) {
            if(this.role !== 'admin') {
                var string_split = evt.target.value.toLowerCase().split(" ");
                for (var i = 0; i < string_split.length; i++) {
                    var j = string_split[i].charAt(0).toUpperCase();
                    string_split[i] = j + string_split[i].substr(1);
                }
                evt.target.value = string_split.join(" ");
                this.videofiles[indexval][varname] = string_split.join(" ");
            }
        },
        titleCase: function(varname, evt) {
            if(this.role !== 'admin') {
                let target = evt.target;
                var start = target.selectionStart;
                var end = target.selectionEnd;
                var string_split = target.value.toLowerCase().split(" ");
                for ( var i = 0; i < string_split.length; i++ )
                {
                    var j = string_split[i].charAt(0).toUpperCase();
                    string_split[i] = j + string_split[i].substr(1);
                }
                target.value = string_split.join(" ");
                target.setSelectionRange(start, end);
            }
        }
    }
}
</script>


<style scoped>
.select2-container--default .select2-results>.select2-results__options {
    max-height: 300px;
}
.active {
    background: lightgray;
}
#closed_caption.form-control.filename {
    padding-right:0;
    height:auto;
}
</style>
