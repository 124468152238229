<template>
    <div class="">
        <div v-if="errors">
            {{ errors }}
        </div>

        <modal size="modal-lg" v-if="recordPaymentModal" @close="closeRecordPaymentModal">
            <h2 slot="header">Record Payment for Invoice {{ assigned_invoice.invoice_number }}</h2>
            <div class="content" slot="body">
                <form id="finance-form-data">
                    <div v-if="recordPaymentErrors" class="alert mt-3 p-3">
                        <ul>
                            <li v-for="(error, key) in recordPaymentErrors" :key="key" class="text-danger">
                                {{ error[0] }}
                            </li>
                        </ul>
                    </div>

                    <div class="invoice-wrapper-grey mb-2">
                        <div class="custom-control custom-radio d-inline-block mr-5">
                            <input
                                v-model="recordPaymentType"
                                type="radio"
                                value="check"
                                class="custom-control-input"
                                id="refund_type_check"
                            >
                            <label for="refund_type_check" class="custom-control-label no-border pl-1">Check</label>
                        </div>

                        <div class="custom-control custom-radio d-inline-block mr-5">
                            <input
                                v-model="recordPaymentType"
                                type="radio"
                                value="ach"
                                class="custom-control-input"
                                id="refund_type_ach"
                            >
                            <label for="refund_type_ach" class="custom-control-label no-border pl-1">ACH</label>
                        </div>
                    </div>
                    <div v-if="recordPaymentType === 'check'" class="invoice-wrapper-grey mt-0">
                        <div class="invoice-wrap-half">
                            <label>Check Number</label>
                            <input type="text" name="check" v-model="recordPaymentInfo.check_number">
                        </div>
                        <div class="invoice-wrap-half last">
                            <label>Payment Amount</label>
                            <input type="text" name="amount" v-model="recordPaymentInfo.check_amount">
                        </div>
                        <div class="invoice-wrap-full">
                            <label>Note</label>
                            <textarea name="note" v-model="recordPaymentInfo.check_notes"></textarea>
                        </div>
                    </div>
                    <div v-if="recordPaymentType === 'ach'" class="invoice-wrapper-grey mt-0">
                        <div class="invoice-wrap-half">
                            <label>ACH Number</label>
                            <input type="text" name="check" v-model="recordPaymentInfo.ach_number">
                        </div>
                        <div class="invoice-wrap-half last">
                            <label>Payment Amount</label>
                            <input type="text" name="amount" v-model="recordPaymentInfo.ach_amount">
                        </div>
                        <div class="invoice-wrap-half">
                            <label>Deposit Date</label>
                            <flat-pickr
                                id="event_date"
                                v-model="recordPaymentInfo.deposit_date"
                                placeholder="Select date"
                                :config="{ dateFormat: 'm-d-Y', maxDate: 'today' }"
                            ></flat-pickr>
                        </div>
                        <div class="invoice-wrap-half last">
                            <label>Transaction ID</label>
                            <input type="text" name="check" v-model="recordPaymentInfo.transaction_id">
                        </div>
                        <div class="invoice-wrap-full">
                            <label>Note</label>
                            <textarea name="note" v-model="recordPaymentInfo.ach_notes"></textarea>
                        </div>
                    </div>
                </form>
            </div>
            <div class="form-group-submit" slot="footer">
                <button class="pull-left btn btn-transparent" @click="closeRecordPaymentModal">Cancel</button>
                <button
                    class="btn btn-primary pull-right"
                    @click.prevent="recordPayment"
                    :disabled="!recordPaymentType || recordPaymentInProgress"
                >
                    <i v-if="recordPaymentInProgress" class="fa fa-spinner fa-spin"></i>
                    Record Payment
                </button>
            </div>
        </modal>

        <modal v-if="refundModal" size="modal-lg" @close="closeRefundModal">
            <h2 slot="header">Refund for Invoice {{ assigned_invoice.invoice_number }} </h2>
            <div class="content" slot="body">
                <form id="finance-refund-form-data" class="finance-form-data">
                    <div v-if="refundErrors" class="alert mt-3 p-3">
                        <ul>
                            <li v-for="(error, key) in refundErrors" :key="key" class="text-danger">
                                {{ error[0] }}
                            </li>
                        </ul>
                    </div>
                    <div class="invoice-wrapper-grey">
                        <div class="invoice-wrap-full">
                            <label>Refund Amount</label>
                            <input type="text" name="refund_amount" v-model.number="refundInfo.refund_amount">
                            <p class="text-danger pt-1" v-if="refundInfo.refund_amount > assigned_invoice.amount_paid">
                                Amount specified is more than the invoice amount
                            </p>
                            <p class="pt-1">Maximum amount : ${{ assigned_invoice.amount_paid }}</p>
                        </div>
                        <div class="invoice-wrap-full">
                            <label style="width:100%">Type of refund</label>
                        </div>
                        <div class="d-flex align-items-center justify-content-start mt-2">
                            <div class="custom-control custom-radio mr-5">
                                <input type="radio" v-model="refundInfo.refund_type" value="check" class="custom-control-input"
                                       id="refund_type_check" name="refund_type">
                                <label class="custom-control-label no-border pl-1" for="refund_type_check">Check</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input type="radio" v-model="refundInfo.refund_type" value="credit_card"
                                       class="custom-control-input" id="refund_type_credit_card" name="refund_type">
                                <label class="custom-control-label no-border pl-1" for="refund_type_credit_card">Credit
                                    Card </label>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
            <div class="form-group-submit" slot="footer">
                <button class="btn btn-cancel pull-left" @click="closeRefundModal">Cancel</button>
                <button class="btn btn-primary pull-right"
                        :disabled="!refundInfo.refund_amount || refundInfo.refund_type == null || refundInfo.refund_amount > assigned_invoice.amount_paid"
                        @click.prevent="processRefund(assigned_invoice)">Process Refund
                </button>
            </div>
        </modal>

        <div class="search-filters invoice-filter-fields">

            <div class="choices-filter-group">
                <input class="form-control" placeholder="Invoice Number" type="text" v-model="query.invoice_number">
            </div>
            <div class="choices-filter-group">
                <input class="form-control" placeholder="Order Number" type="text" v-model="query.order_number">
            </div>
            <div class="choices-filter-group">
                <input class="form-control" placeholder="Submitter Name" type="text" v-model="query.submitter_name">
            </div>
            <div class="choices-filter-group">
                <input class="form-control" placeholder="Billing Name" type="text" v-model="query.billing_name">
            </div>
            <div class="choices-filter-group">
                <input class="form-control" placeholder="Invoice Total" type="text" v-model="query.invoice_total">
            </div>
            <div class="choices-filter-group">
                <input class="form-control" placeholder="Payment Total" type="text" v-model="query.payment_total">
            </div>
            <div class="choices-filter-group">
                <input class="form-control" placeholder="Balance" type="text" v-model="query.outstanding_balance">
            </div>

            <div class="choices-filter-group double">
                <div class="pl-2">
                    <h4>Date</h4>
                    <label class="date" for="date_from">
                        <calendar-icon class="svg-accent svg-16"></calendar-icon>
                    </label>
                    <flat-pickr
                        id="date_from"
                        v-model="query.date_from"
                        :config="{ dateFormat: 'm-d-Y', minDate: '1-1-2018', maxDate: 'today' }"
                        placeholder="Select date"
                    />
                </div>
                <div class="pl-2">
                    <h4>To</h4>
                    <label class="date" for="date_to">
                        <calendar-icon class="svg-accent svg-16"></calendar-icon>
                    </label>
                    <flat-pickr
                        id="date_to"
                        v-model="query.date_to"
                        :config="{ dateFormat: 'm-d-Y', minDate: '1-1-2018', maxDate: 'today' }"
                        placeholder="Select date"
                    />
                </div>
                <span v-if="query.date_to && new Date(query.date_to) < new Date(query.date_from)" class="text-danger">
                    End date must be after start date.
                </span>
            </div>

            <div class="choices-filter-group payment-input-wrapper">
                <v-select
                    id="payment_status"
                    v-model="query.payment_status"
                    multiple
                    placeholder="Payment Status"
                    :options="[
                        'Paid',
                        'Unpaid',
                        'Draft',
                        'Void',
                    ]"
                />
            </div>
            <div class="choices-filter-group payment-input-wrapper">
                <v-select
                    id="reconciled"
                    v-model="query.reconciled_status"
                    placeholder="Reconciled Status"
                    :options="[
                        'Reconciled',
                        'Unreconciled',
                    ]"
                />
            </div>
            <div class="pull-right filter-buttons">
                <button class="clear-button no-border" @click="clearFilters">
                    <close-icon class="svg-16"></close-icon>
                    Clear Filters
                </button>
            </div>

        </div>

        <h3>Invoices</h3>

        <div class="entries-table-wrap">
            <table id="invoice-table" class="nice-table">
                <thead>
                <tr class="">
                    <th class="cursor-pointer" @click="setSort('submitter_name')" :class="{ 'active-sort': query.sort === 'submitter_name' }">
                        Submitter
                        <up-chevron-icon v-if="query.sort_order !== 'desc' && query.sort === 'submitter_name'" />
                        <down-chevron-icon v-else />
                    </th>
                    <th class="cursor-pointer" @click="setSort('billing_contact')" :class="{ 'active-sort': query.sort === 'billing_contact' }">
                        Billing Contact
                        <up-chevron-icon v-if="query.sort_order !== 'desc' && query.sort === 'billing_contact'" />
                        <down-chevron-icon v-else />
                    </th>
                    <th style="width: 12em" class="th-invoice cursor-pointer" @click="setSort('invoice_number')" :class="{ 'active-sort': query.sort === 'invoice_number' }">
                        Invoice #
                        <up-chevron-icon v-if="query.sort_order !== 'desc' && query.sort === 'invoice_number'" />
                        <down-chevron-icon v-else />
                    </th>
                    <template v-if="invoiceType === 'events'">
                        <th class="cursor-pointer" @click="setSort('event_info')" :class="{ 'active-sort': query.sort === 'event_info' }">
                            Event Info
                            <up-chevron-icon v-if="query.sort_order !== 'desc' && query.sort === 'event_info'" />
                            <down-chevron-icon v-else />
                        </th>
                        <th class="cursor-pointer" @click="setSort('event_network')" :class="{ 'active-sort': query.sort === 'event_network' }">
                            Network
                            <up-chevron-icon v-if="query.sort_order !== 'desc' && query.sort === 'event_network'" />
                            <down-chevron-icon v-else />
                        </th>
                    </template>
                    <th class="cursor-pointer" @click="setSort('invoice_total')" :class="{ 'active-sort': query.sort === 'invoice_total' }">
                        Invoice Total
                        <up-chevron-icon v-if="query.sort_order !== 'desc' && query.sort === 'invoice_total'" />
                        <down-chevron-icon v-else />
                    </th>
                    <th class="cursor-pointer" @click="setSort('outstanding_balance')" :class="{ 'active-sort': query.sort === 'outstanding_balance' }">
                        Outstanding Balance
                        <up-chevron-icon v-if="query.sort_order !== 'desc' && query.sort === 'outstanding_balance'" />
                        <down-chevron-icon v-else />
                    </th>
                    <th class="cursor-pointer" @click="setSort('payment_status')" :class="{ 'active-sort': query.sort === 'payment_status' }">
                        Payment Status
                        <up-chevron-icon v-if="query.sort_order !== 'desc' && query.sort === 'payment_status'" />
                        <down-chevron-icon v-else />
                    </th>
                    <th style="width: 15em;">
                        Actions
                    </th>
                    <th>
                        Reconcile
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(invoice, i) in invoices.data">
                    <td class="email">
                        <div class="td-line-break">{{ invoice.user.first_name }} {{ invoice.user.last_name }}<br/>
                            <span>{{ invoice.user.email }}</span>
                        </div>
                    </td>
                    <td class="">
                        <div v-if="invoice.contact">
                            {{ invoice.contact.name }}<br/>
                            <span>{{ invoice.contact.email }}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <a v-if="invoiceType === 'events'"
                               :href="'/finance/invoice/'+invoice.id">{{ invoice.invoice_number }}</a>
                            <a v-if="invoiceType === 'orders'"
                               :href="'/invoice/'+invoice.id+'/payment'">{{ invoice.invoice_number }}</a>
                        </div>
                    </td>
                    <template v-if="invoiceType === 'events'">
                        <td>
                            <div>
                                <div>{{ invoice.subject.event_title }}</div>
                                <div>{{ invoice.subject.event_date }}</div>
                            </div>
                        </td>
                        <td>
                            <div>
                                {{ invoice.subject.network_platform === 'Other'
                                    ? invoice.subject.network_platform_other
                                    : invoice.subject.network_platform }}
                            </div>
                        </td>
                    </template>
                    <td>
                        <div>{{ formatPrice(invoice.amount) }}</div>
                    </td>
                    <td>
                        <div class="entry__category">{{ formatPrice(invoice.amount_due) }}</div>
                    </td>
                    <td class="pl-2 text-capitalize">
                        {{ invoice.status }}
                    </td>

                    <td>
                        <div>
                            <template v-if="invoice.amount_due > 0">
                                <button class="btn-finance btn-sm mr-2 py-1" @click.prevent="openAssignModal(i)">
                                    <plus-circle-icon class="svg-16 svg-black"></plus-circle-icon>
                                    Record Payment
                                </button>
                                <a :href="'/invoice/'+invoice.id+'/payment'" class="btn-finance mr-2 text-nowrap py-1">
                                    <plus-circle-icon class="svg-16 svg-black mr-1"></plus-circle-icon><!--
                                    -->Credit Card
                                </a>
                            </template>
                            <a v-if="invoice.amount_due === 0" :href="'/invoice/download/'+invoice.id"
                               class="btn-finance mr-2 text-nowrap py-1">
                                <download class="svg-16 svg-black mr-1"></download><!--
                                    -->Download Invoice
                            </a>
                            <a v-if="invoice.amount_paid > 0" href="#" class="btn-finance mr-2 text-nowrap py-1"
                               @click.prevent="assigned_invoice = invoice; refundModal = true">
                                <refresh class="svg-16 svg-black mr-1"></refresh><!--
                                -->Refund
                            </a>
                        </div>
                    </td>
                    <td>
                        <div>
                            <a v-if="!invoice.is_reconciled" href="#" class="btn-finance"
                               @click.prevent="reconcileInvoice(invoice)">
                                Reconcile</a>
                            <span v-else><checkmark class="svg-16 svg-black"></checkmark> Reconciled</span>
                        </div>
                    </td>
                </tr>

                </tbody>
            </table>
        </div>
        <div style="float:right;">
            <pagination :data="invoices" @pagination-change-page="clickPaginationCallback" :limit="3">
                <span slot="prev-nav">&lt; Previous</span>
                <span slot="next-nav">Next &gt;</span>
            </pagination>
        </div>
        <div class="dataTables_info" v-if="from && to">Showing {{ from }}-{{ to }} of {{ total }} invoices
        </div>
        <div class="dataTables_info" v-else>Showing 0 invoices</div>
        <div class="dataTables_length admin-entry-table">
            <label>
                <span class="pr-2">Show</span>
                <select class="plain-select num-entries-select" v-model.number="query.num">
                    <option :value="10">10 Invoices</option>
                    <option :value="20">20 Invoices</option>
                    <option :value="50">50 Invoices</option>
                    <option :value="100">100 Invoices</option>
                </select>
                <span>per page</span>
            </label>
        </div>
    </div>
</template>

<script>
// register modal component
import modal from '../components/Modal.vue';
import CalendarIcon from "@/Icons/calendar.vue";
import Refresh from "@/Icons/refresh.vue";
import PlusCircleIcon from "@/Icons/plus-circle.vue";
import Download from "@/Icons/download.vue";
import CloseIcon from "@/Icons/close.vue";
import Checkmark from "@/Icons/checkmark.vue";
import DownChevronIcon from "@/Icons/down_chevron.vue";
import UpChevronIcon from "@/Icons/up-chevron.vue";

const queryParameters = {
    invoice_number: '',
    order_number: '',
    submitter_name: '',
    billing_name: '',
    invoice_total: '',
    payment_total: '',
    outstanding_balance: '',
    date_from: '1-1-2025',
    date_to: '',
    payment_status: [],
    reconciled_status: '',
    sort: '',
    sort_order: '',
    num: 20,
};

const recordPaymentInfoDefaults = {
    // for checks only
    check_number: '',
    check_amount: 0,
    check_notes: '',

    // for ACH only
    ach_number: '',
    ach_amount: 0,
    deposit_date: '',
    transaction_id: '',
    ach_notes: '',
};

const refundInfoDefaults = {
    refund_amount: null,
    refund_type: null,
};

export default {
    name: "FinancialsTable",

    components: {
        UpChevronIcon, DownChevronIcon,
        Checkmark,
        CloseIcon,
        Download,
        PlusCircleIcon,
        Refresh,
        CalendarIcon,
        modal,
    },

    props: {
        invoiceType: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            reloadOnQueryChange: false,
            errors: false,
            page: 1,
            total: 0,
            from: 0,
            to: 0,
            invoices: {},
            assigned_invoice: [],
            recordPaymentModal: false,
            recordPaymentType: null,
            recordPaymentInProgress: false,
            recordPaymentErrors: null,
            refundModal: false,
            refundInfo: structuredClone(refundInfoDefaults),
            refundErrors: null,
            query: structuredClone(queryParameters),
            recordPaymentInfo: structuredClone(recordPaymentInfoDefaults),
        }
    },
    mounted() {
        // Load saved query from local storage
        for (const key of Object.keys(queryParameters)) {
            const storedValue = localStorage.getItem(`fyc-admin-finance-${this.invoiceType}-query-${key}`);
            if (storedValue !== null) {
                switch (key) {
                    case 'payment_status':
                        this.query[key] = storedValue.split(',').filter(value => value !== '');
                        break;
                    case 'num':
                        this.query[key] = parseInt(storedValue);
                        break;
                    default:
                        this.query[key] = storedValue;
                }
            }
        }

        // Load saved page from query storage
        this.page = localStorage.getItem(`fyc-admin-finance-${this.invoiceType}-page`) || 1;

        this.fetchInvoices();
    },
    methods: {
        processRefund(invoice) {
            // post to finance/refund
            axios
                .post('/finance/refund', {
                    'refund_type': this.refundInfo.refund_type,
                    'refund_amount': this.refundInfo.refund_amount,
                    'invoice_id': invoice.id
                })
                .then(res => {
                    this.errors = "Refund Complete";
                    this.refundInfo = structuredClone(refundInfoDefaults);
                    this.refundModal = false;
                    // reload page
                    window.location.reload();
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        this.refundErrors = err.response.data.errors;
                        return;
                    }

                    const errorMessages = [];

                    if (err.response.status === 400) {
                        errorMessages.push(err.response.data.message);
                    } else {
                        errorMessages.push('An error occurred while processing your request. Please try again later.');
                    }

                    this.refundErrors = {'errors': errorMessages};
                });
        },

        setSort(sort) {
            // Disable query watcher until next fetch
            this.reloadOnQueryChange = false;

            if (this.query.sort === sort) {
                // Toggle sort order if setting sort on the same column
                this.query.sort_order = (this.query.sort_order === 'desc') ? 'asc' : 'desc';
            } else {
                // Set the sort order to asc if setting sort on a new column
                this.query.sort = sort;
                this.query.sort_order = 'asc';
            }

            this.resetPageNumber();
            this.fetchInvoices();
        },

        clearFilters() {
            // Disable query watcher until next fetch
            this.reloadOnQueryChange = false;

            // Reset query parameters
            this.query = structuredClone(queryParameters);

            // Remove saved search
            for (const key of Object.keys(queryParameters)) {
                localStorage.removeItem(`fyc-admin-finance-${this.invoiceType}-query-${key}`);
            }
            this.fetchInvoices();
        },

        fetchInvoices() {
            axios
                .get('/api/finance', {
                    params: {
                        invoice_type: this.invoiceType,
                        page: this.page,
                        invoice_number: this.query.invoice_number,
                        order_number: this.query.order_number,
                        submitter_name: this.query.submitter_name,
                        billing_name: this.query.billing_name,
                        invoice_total: this.query.invoice_total,
                        payment_total: this.query.payment_total,
                        outstanding_balance: this.query.outstanding_balance,
                        date_from: this.query.date_from,
                        date_to: this.query.date_to,
                        payment_status: this.query.payment_status,
                        reconciled_status: this.query.reconciled_status,
                        sort: this.query.sort,
                        sort_order: this.query.sort_order,
                        num: this.query.num,
                    }
                })
                .then(res => {
                    this.invoices = res.data;
                    this.total = res.data.total;
                    this.from = res.data.from;
                    this.to = res.data.to;

                    // Save query to local storage
                    for (const key of Object.keys(queryParameters)) {
                        localStorage.setItem(`fyc-admin-finance-${this.invoiceType}-query-${key}`, this.query[key] ?? '');
                    }

                    // Save page to local storage
                    localStorage.setItem(`fyc-admin-finance-${this.invoiceType}-page`, this.page);
                })
                .catch(err => {
                    this.errors = 'Error contacting the server : ';
                    this.errors += err;
                    console.log(err)
                })
                .finally(() => {
                    this.reloadOnQueryChange = true;
                });
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace(',', '.')
            return '$' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        openAssignModal(i) {
            this.assigned_invoice = this.invoices.data[i];

            // Pre-fill amounts
            this.recordPaymentInfo.check_amount = this.assigned_invoice.amount_due.toFixed(2);
            this.recordPaymentInfo.ach_amount = this.assigned_invoice.amount_due.toFixed(2);

            this.recordPaymentModal = true;
        },
        reconcileInvoice(invoice) {
            if (!confirm('Are you sure you want to reconcile this invoice?')) {
                return;
            }

            axios
                .post('/api/finance/reconcile', {
                    'invoice_id': invoice.id
                })
                .then(() => {
                    this.errors = "Invoice Reconciled";
                    window.location.reload();
                })
                .catch(() => {
                    alert('An error occurred while processing your request. Please try again later.');
                });
        },
        recordPayment() {
            if (!this.recordPaymentType) {
                alert("Payment type needs to be filled in");
                return;
            }

            if (this.recordPaymentType === 'check' && this.recordPaymentInfo.check_amount <= 0) {
                alert("Payment amount needs to be filled in");
                return;
            }

            if (this.recordPaymentType === 'ach' && this.recordPaymentInfo.ach_amount <= 0) {
                alert("Payment amount needs to be filled in");
                return;
            }

            this.recordPaymentInProgress = true;

            axios
                .post('/api/finance/record-payment', {
                    invoice_id: this.assigned_invoice.id,
                    payment_type: this.recordPaymentType,
                    ...this.recordPaymentInfo,
                })
                .then(res => {
                    this.recordPaymentInProgress = false;

                    this.errors = res.data;

                    this.closeRecordPaymentModal();

                    window.location.reload();
                })
                .catch(err => {
                    this.recordPaymentInProgress = false;

                    if (err.response.status === 422) {
                        this.recordPaymentErrors = err.response.data.errors;
                        return;
                    }

                    this.recordPaymentErrors = [
                        ['An error occurred while processing your request. Please try again later.']
                    ];
                });
        },

        clickPaginationCallback(pageNum) {
            this.page = pageNum;
            this.fetchInvoices();
        },

        resetPageNumber() {
            this.page = 1;
            localStorage.removeItem(`fyc-admin-finance-${this.invoiceType}-page`);
        },
        closeRecordPaymentModal() {
            this.recordPaymentInfo = structuredClone(recordPaymentInfoDefaults);
            this.recordPaymentModal = false;
            this.recordPaymentErrors = null;
        },
        closeRefundModal() {
            this.refundInfo = structuredClone(refundInfoDefaults);
            this.refundModal = false;
            this.refundErrors = null;
        },
    },
    watch: {
        query: {
            handler() {
                if (this.reloadOnQueryChange) {
                    this.resetPageNumber();
                    this.fetchInvoices();
                }
            },
            deep: true
        },
    }
}
</script>
